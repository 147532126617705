import { useEffect, useState } from 'react';
import { SupportTicketsItem } from '../../../types';
import { PageLayout } from '../../PageLayout';
import { PageCard } from '../../Compounds/CardWithTitle';
import { useSearchParams } from 'react-router-dom';
import { DetailsDrawer } from '../../Compounds/DetailsDrawer';
import { getMetaData } from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { SupportTicketWizard } from '../../Compounds/Wizards/SupportTicketWizard';
import {
  getTicketLoadingSubject,
  getTickets,
  getTicketSubject,
  OPEN_STATUS,
  useSupportTickets
} from '../../../store/SupportTicket';
import { DashboardWidgets } from '../../Compounds/DashboardWidgets/DashboardWidgets';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { Permission } from '../../../store/GeneralStore';
import {
  GridPreferences,
  getDefaultPreferences,
  getPreferencesByKey,
  getUserPreferencesSubject
} from '../../../store/User';
import { CustomizeDashboardDrawer } from '../../Compounds/CustomizeDashboardDrawer';
import { Tab, Tabs, useTheme } from '@mui/material';
import { filteredClosedTicketsByMonth } from '../../../utils/helpers';
import { UpstackDataGrid } from '../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DefaultTicketGridColumnDefs } from './SupportTicketGridColDefs';
import { getGridColumns } from '../../Compounds/UpstackDataGrid/helpers';
import { TabPanel } from '../../Atoms/TabPanel';

export const SupportTicketsPage = () => {
  const page = 'Tickets';
  const [tickets, setTickets] = useState<SupportTicketsItem[]>([]);
  const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);
  const [search] = useSearchParams();
  const detailId = search.get('detailId');
  const { fetchTickets } = useSupportTickets();
  const [selectedTab, setSelectedTab] = useState(0);
  const [columns, setColumns] = useState<GridSingleSelectColDef[]>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences().content['ticket_grid'] as GridPreferences
  );
  const [tabs, setTabs] = useState<{ name: string; tickets: SupportTicketsItem[] }[]>([]);
  const theme = useTheme();

  // Subscribe to and load data.
  useEffect(() => {
    const ticketStore = getTicketSubject().subscribe((ticketData) => {
      if (ticketData?.length) {
        setTickets(ticketData);

        const tabs = [
          { name: 'Open', tickets: ticketData.filter((item) => OPEN_STATUS.includes(item.status)) },
          { name: 'Recently Closed', tickets: filteredClosedTicketsByMonth(ticketData, 2) },
          { name: 'All', tickets: ticketData }
        ];

        setTabs(tabs);
        setColumns(getGridColumns(tabs?.[selectedTab]?.tickets, DefaultTicketGridColumnDefs, 'ticket_grid'));
      }
    });
    const loadingSubscription = getTicketLoadingSubject().subscribe((loading) => setTicketsLoading(loading));
    const settingsSub = getUserPreferencesSubject().subscribe(() => {
      const gridPrefs = getPreferencesByKey('ticket_grid') as GridPreferences;
      if (gridPrefs) setGridSettings(gridPrefs);
    });

    fetchTickets();

    return () => {
      if (ticketStore) ticketStore?.unsubscribe();
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (settingsSub) settingsSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (tabs.length) handleTabChange(0);
  }, [tabs.length]);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
    const columns = getGridColumns(tabs?.[index]?.tickets, DefaultTicketGridColumnDefs, 'ticket_grid');
    setColumns(columns);
  };

  const tableCardActions = () => (
    <PermissibleRender requiredPermissions={[Permission.CREATE_TICKETS_FROM_TICKETS]}>
      <SupportTicketWizard
        ctaClassName="text-[indigo] text-sm"
        onFormSubmissionHandler={async () => setTickets(getTickets())}
      />
    </PermissibleRender>
  );

  const cardActions = () => <CustomizeDashboardDrawer title="Support" />;

  return (
    <PageLayout pageTitle="Support">
      <>
        <PageCard
          title="Dashboard"
          cardActions={cardActions()}>
          <DashboardWidgets
            inventoryAssets={[]}
            pageTitle={page}
            tickets={tickets}
          />
        </PageCard>

        <PageCard
          childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
          title={page}
          cardActions={tableCardActions()}>
          <>
            <Tabs
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgb(247 247 247)',
                borderBottom: '1px solid rgb(229 231 235)',
                borderTop: '1px solid rgb(229 231 235)'
              }}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  height: selectedTab <= tabs.length - 1 ? 2 : 0,
                  borderRadius: 1
                }
              }}
              value={selectedTab}
              onChange={(_e, index) => handleTabChange(index)}>
              {tabs.map((tab, index) => (
                <Tab
                  data-cy={`tab-link-${tab.name}`}
                  sx={{ textTransform: 'none', fontSize: '.8rem', color: 'black' }}
                  key={index}
                  label={`${tab.name} (${tab.tickets.length})`}
                />
              ))}
            </Tabs>
            {tabs.map((tab, index) => (
              <TabPanel
                key={`${index}`}
                index={index}
                value={selectedTab}>
                <UpstackDataGrid
                  title={`Support Tickets - ${tab.name}`}
                  rows={tab.tickets}
                  columns={columns}
                  pinnedColumns={{ left: ['details', 'account_id'] }}
                  loadingData={ticketsLoading}
                  page="ticket_grid"
                  gridSettings={gridSettings}
                  showSearch={false}
                />
              </TabPanel>
            ))}
          </>
        </PageCard>
        <div className="h-2"></div>
        <DetailsDrawer
          data={tickets?.find((t) => t.id === detailId) || ({} as SupportTicketsItem)}
          showDrawer={!!detailId}
          link={`/support-ticketing/${detailId}`}
          {...getMetaData('support')}
        />
      </>
    </PageLayout>
  );
};
